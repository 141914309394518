import { state } from ":mods";
import { createEffect } from "solid-js";
import { getCourses } from "../../api";

export function DescriptionSection() {
  const images = {
    src: "/images/figma_image_rect.png", 
    alt: "course img"
  };
  const CourseInfo = [
    { 
      id: 1,
      image: images,
      title: 'Part 1 - Foundation Programme - Ideas Challenge',
      description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    },
    { 
      id: 2,
      image: images,
      title: 'Part 2 - Advanced Programme - Exhibition Hack-a-thon',
      description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    },
    { 
      id: 3,
      image: images,
      title: 'Part 3 - UK Exchange Programme',
      description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    }
  ]
  const $coursesList = state.create([]);
  // commet this to see the states data on the page from the object above
  const $db_courses = state.createAsync(getCourses);
    // commet this to see the states data on the page from the object above
  createEffect(() => {
    $coursesList.set($db_courses.value?.data);
  });
  
  return (
    <section class="flex flex-col bg#paper items-center">
      <div class="flex flex-col min-h-300px gap-40px justify-center items-center my-80px">
        {$coursesList.value?.map(({ id, image, title, description }) => {
          return (
            <div class="flex gap-22px">
              {!!image.src ? (
                <img src={image.src} alt={image.alt} width={197} height={197} />
              ) : (
                <div class="bg#p w-197px h-197px"></div>
              )}
              <article class="w-710px">
                <p class="text-25px font-bold">{title}</p>
                <p class="text-16px leading-24px">{description}</p>
              </article>
            </div>
          );
        })}
      </div>
      <hr class="max-w-screen w-screen h-2px bg#p" />
    </section>
  );
}
